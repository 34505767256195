import axios from 'axios';
import { API_URL } from './api';
import { removeToken, setToken } from './tokenStorge';

axios.interceptors.request.use(
	config => config,
	e => Promise.reject(e)
);
axios.interceptors.response.use(
	response => response,
	e => {
		if (typeof e?.toJSON === 'function') {
			const error = e?.toJSON();

			if (error?.status === 401) {
				// console.log(error, "err");
				removeToken();
				window.location.href = '/login';
			}
			if (error?.message === 'Network Error') {
				// toast.warning(
				//   localStorage.getItem("i18nextLng") === "uz"
				//     ? "Internetingiz uzildi!"
				//     : "Нет подключения к Интернету!"
				// );
			}
			if (process.env.NODE_ENV === 'development') {
				// console.log(error);
			}
		}
		return Promise.reject(e);
	}
);
const Axios = (Api_Url, access_token) => {
	const isLocal = window.location.href.includes('localhost');
	let token = '';
	if (isLocal) {
		token = access_token
			? access_token
			: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5MjQzOTc1LCJpYXQiOjE3MjgzNzk5NzUsImp0aSI6IjYxZWVlZjU5MWVjYjQ5NDI4MTlkYjk2Y2NjOGE3NmI1IiwidXNlcl9pZCI6MywidXNlcm5hbWUiOiI1MTMwNjA1NTQ4MDAzMCIsImVtYWlsIjoiIiwiZnVsbF9uYW1lIjoiICIsInBlcm1pc3Npb25zIjpbInZpZXdfYm9yZGVyY3Jvc3MiXSwicm9sZXMiOlsiYmlvZ3JhcGhpY2FsX2RhdGFfb2ZfdGhlX21pZ3JhbnQiXX0.k1Clrb8QNjt5TV-iX0B5drh_tMFT3SQpcGk0VYOmGROBlgbwGvvQ2Du_nqXRi5KxNQPYevRceZHWEecSLM-ncmnq7bQzcLhGbohAM8EXdHiLgDsGxyVZYPzurjwDBEZly4jRe9NY1F79llExhXF9HhPanTU3vmgq_PxodkMrNhDuH_wfMYPvIISqNznQDVaTUWVgmJDUxcforC9ozbagpnLHKkm40r4nzm-MPGG9fpedQ21-ZKYj2ypwcRPdkKcVlo0a1HdjlEUl3yUSFTZY6MH-MOm6-1yea8ZjP3gIc5-0wzhxMvMYBCYK_UckzC0P4nBPa3VREerRKnqZKM70mQ';
		setToken(token);
	} else {
		token = access_token ? access_token : localStorage.getItem('token');
	}

	const defaultOptions = {
		baseURL: Api_Url ? Api_Url : API_URL,
		headers: token
			? {
					Authorization: `Bearer ${token}`,
					lang: localStorage.getItem('i18nextLng') || 'uz',
			  }
			: { lang: localStorage.getItem('i18nextLng') || 'uz' },
		params: { lan: localStorage.getItem('i18nextLng') || 'ru' },
	};

	return {
		get: (url, options = {}) => axios.get(url, { ...defaultOptions, ...options }),
		post: (url, data, options = {}) => axios.post(url, data, { ...defaultOptions, ...options }),
		put: (url, data, options = {}) => axios.put(url, data, { ...defaultOptions, ...options }),
		delete: (url, options = {}) => axios.delete(url, { ...defaultOptions, ...options }),
	};
};
export default Axios;
