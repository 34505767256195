import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const SlidingTab = ({ tabUnderlineLeft, tabUnderlineWidth, allTabs, activeTabIndex, setActiveTabIndex, tabsRef }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const childIdType = searchParams.get('child-id-type') ? searchParams.get('child-id-type') : 'M';
	setActiveTabIndex(searchParams.get('child-id-type') === 'P' ? 1 : 0);
	return (
		<div className='flex-row relative mx-auto inline-flex rounded-[100px] bg-alabaster px-1 backdrop-blur-sm'>
			<span className='absolute bottom-0 top-0 -z-10 flex overflow-hidden rounded-[100px] py-1 transition-all duration-200' style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}>
				<span className='h-full w-full rounded-[100px] bg-[#4680FF] ' />
			</span>
			{allTabs.map((tab, index) => {
				const isActive = tab?.childIdType === childIdType;

				return (
					<Link
						to={tab.path}
						key={index}
						ref={el => (tabsRef.current[index] = el)}
						className={`my-auto cursor-pointer  select-none rounded-full px-5 text-center text-base flex justify-center items-center font-semibold ${
							isActive ? ' !text-white hover:!text-white' : 'text-black text-grayDark  hover:!text-black'
						} h-12`}
						onClick={() => setActiveTabIndex(index)}
					>
						{tab.name}
					</Link>
				);
			})}
		</div>
	);
};

export default SlidingTab;
