import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Layout from '../sections/layoutSections/Layout';
import { roles } from '../utils/roleManager/roles';

export const routes = [
	{
		element: (
			<Layout>
				<Outlet />
			</Layout>
		),
		handle: {
			title: 'Dashboard',
		},
		children: [
			{
				index: true,
				// component: lazy(() => import("../components/main_pages/Home")),
				component: lazy(() => import('../components/main_pages/Home2')),
				handle: {
					title: 'Home',
					roles: [roles.allow_any],
				},
			},
			{
				path: 'organized-work',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/Home3')),
						handle: {
							title: 'Organized Work',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: 'statistics/:childId',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/Home')),
						handle: {
							title: 'User Info',
							roles: [roles.allow_any],
						},
					},
				],
			},
			//   {
			//     path: "home2",
			//     children: [
			//       {
			//         index: true,
			//         component: lazy(() => import("../components/main_pages/Home2")),
			//         handle: {
			//           title: "User Info",
			//           roles: [roles.allow_any],
			//         },
			//       },
			//     ],
			//   },
			//   {
			//     path: "home3",
			//     children: [
			//       {
			//         index: true,
			//         component: lazy(() => import("../components/main_pages/Home3")),
			//         handle: {
			//           title: "User Info",
			//           roles: [roles.allow_any],
			//         },
			//       },
			//     ],
			//   },
			//   {
			//     path: "bordercrossing/",
			//     children: [
			//       {
			//         path: "citizens-abroad",
			//         children: [
			//           {
			//             index: true,
			//             component: lazy(() =>
			//               import("../components/bordercrossing_pages/CitizensAbroad")
			//             ),
			//             handle: {
			//               title: "Граждане за рубежом",
			//               roles: [roles.allow_any],
			//             },
			//           },
			//           {
			//             path: "list",
			//             children: [
			//               {
			//                 index: true,
			//                 component: lazy(() =>
			//                   import(
			//                     "../components/bordercrossing_pages/CitizensAbroadList"
			//                   )
			//                 ),
			//                 handle: {
			//                   title: "Граждане за рубежом",
			//                   roles: [roles.allow_any],
			//                 },
			//               },
			//             ],
			//           },
			//         ],
			//       },

			//       {
			//         path: "citizens-abroaddays",
			//         children: [
			//           {
			//             index: true,
			//             component: lazy(() =>
			//               import(
			//                 "../components/bordercrossing_pages/CitizensAbroadDays"
			//               )
			//             ),
			//             handle: {
			//               title: "Граждане за рубежом (более 90 дней)",
			//               roles: [roles.allow_any],
			//             },
			//           },
			//           {
			//             path: "list",
			//             children: [
			//               {
			//                 index: true,
			//                 component: lazy(() =>
			//                   import(
			//                     "../components/bordercrossing_pages/CitizensAbroadDaysList"
			//                   )
			//                 ),
			//                 handle: {
			//                   title: "Граждане за рубежом (более 90 дней)",
			//                   roles: [roles.allow_any],
			//                 },
			//               },
			//             ],
			//           },
			//         ],
			//       },
			//       {
			//         path: "returned-citizens",
			//         children: [
			//           {
			//             index: true,
			//             component: lazy(() =>
			//               import("../components/bordercrossing_pages/ReturnedCitizens")
			//             ),
			//             handle: {
			//               title: "Вернувшиеся граждане",
			//               roles: [roles.allow_any],
			//             },
			//           },
			//           {
			//             path: "list",
			//             children: [
			//               {
			//                 index: true,
			//                 component: lazy(() =>
			//                   import(
			//                     "../components/bordercrossing_pages/ReturnedCitizensList"
			//                   )
			//                 ),
			//                 handle: {
			//                   title: "Вернувшиеся граждане",
			//                   roles: [roles.allow_any],
			//                 },
			//               },
			//             ],
			//           },
			//         ],
			//       },
			//       {
			//         path: "citizens-country",
			//         children: [
			//           {
			//             index: true,
			//             component: lazy(() =>
			//               import(
			//                 "../components/bordercrossing_pages/ForeignCitizensCountry"
			//               )
			//             ),
			//             handle: {
			//               title: "Иностранные граждане в стране",
			//               roles: [roles.allow_any],
			//             },
			//           },
			//         ],
			//       },
			//     ],
			//   },
			{
				path: 'migrant/:id',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/UserInfo')),
						handle: {
							title: 'User Info',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: 'accesses',
				children: [
					{
						path: 'organizations',
						children: [
							{
								index: true,
								component: lazy(() => import('../components/accesses_pages/Accesses')),
								handle: {
									title: 'Accesses',
									roles: [roles.allow_any],
								},
							},
							{
								path: ':id',
								children: [
									{
										index: true,
										component: lazy(() => import('../components/accesses_pages/AccessesItem')),
										handle: {
											title: 'Accesses item',
											roles: [roles.allow_any],
										},
									},
								],
							},
						],
					},
					{
						path: 'employees',
						children: [
							{
								index: true,
								component: lazy(() => import('../components/accesses_pages/AccessesEmployeesList')),
								handle: {
									title: 'AccessesEmployees',
									roles: [roles.allow_any],
								},
							},
							{
								path: 'add',
								component: lazy(() => import('../components/accesses_pages/AccessesEmployees')),
								handle: {
									title: 'AccessesEmployees add',
									roles: [roles.allow_any],
								},
							},
							{
								path: ':id',
								component: lazy(() => import('../components/accesses_pages/AccessesEmployeesListItem')),
								handle: {
									title: 'AccessesEmployees list item',
									roles: [roles.allow_any],
								},
							},
						],
					},
				],
			},
			//   {
			//     path: "immigration",
			//     children: [
			//       {
			//         path: "to-come",
			//         children: [
			//           {
			//             index: true,
			//             component: lazy(() =>
			//               import("../components/immigragion_pages/ToCome")
			//             ),
			//             handle: {
			//               title: "Immigration/To-come",
			//               roles: [roles.allow_any],
			//             },
			//           },
			//         ],
			//       },
			//       {
			//         path: "to-leave",
			//         children: [
			//           {
			//             index: true,
			//             component: lazy(() =>
			//               import("../components/immigragion_pages/ToLeave")
			//             ),
			//             handle: {
			//               title: "Immigration/To-leave",
			//               roles: [roles.allow_any],
			//             },
			//           },
			//         ],
			//       },
			//     ],
			//   },
			//   {
			//     path: "emigration",
			//     children: [
			//       {
			//         path: "to-come",
			//         children: [
			//           {
			//             index: true,
			//             component: lazy(() =>
			//               import("../components/emigtation_pages/ToCome")
			//             ),
			//             handle: {
			//               title: "Emigration/To-comed",
			//               roles: [roles.allow_any],
			//             },
			//           },
			//         ],
			//       },
			//       {
			//         path: "to-leave",
			//         children: [
			//           {
			//             index: true,
			//             component: lazy(() =>
			//               import("../components/emigtation_pages/ToLeave")
			//             ),
			//             handle: {
			//               title: "Emigration/To-leave",
			//               roles: [roles.allow_any],
			//             },
			//           },
			//         ],
			//       },
			//     ],
			//   },
			{
				path: '/applications',
				children: [
					{
						path: 'women-in-distress',
						children: [
							{
								index: true,
								component: lazy(() => import('../components/migrant_applications_pages/WomenInDistress')),
								handle: {
									title: 'Women in Distress',
									roles: [roles.allow_any],
								},
							},
						],
					},
				],
			},
			{
				path: '/inspectors',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/inspectors/InspectorsList')),
						handle: {
							title: 'List of Inspectors',
							roles: [roles.allow_any],
						},
					},
					{
						path: ':id',
						children: [
							{
								index: true,
								component: lazy(() => import('../components/inspectors/InspectorsItem')),
								handle: {
									title: ' Inspector  data',
									roles: [roles.allow_any],
								},
							},
						],
					},
				],
			},
		],
	},
	{
		path: 'search',
		component: lazy(() => import('../components/main_pages/Search')),
		handle: {
			title: 'Search',
			roles: [roles.allow_any],
		},
	},
	{
		path: 'login',
		component: lazy(() => import('../components/main_pages/auth/Login')),
		handle: {
			title: 'Login',
			roles: [roles.allow_any],
		},
	},
	{
		path: 'auth-callback',
		component: lazy(() => import('../components/main_pages/auth/CallBack')),
		handle: {
			title: 'Login',
			roles: [roles.allow_any],
		},
	},
	{
		path: '*',
		component: lazy(() => import('../components/main_pages/NotFount')),
		handle: {
			title: '404 | Not Fount',
			roles: [roles.allow_any],
		},
	},
];
